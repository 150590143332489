import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Mention = styled.div`
  color: ${props => props.theme.colors.grey.g700};
  font-size: ${props => props.theme.fonts.sizes.x_small};

  a {
    color: ${props => props.theme.colors.primary};
  }
`
export function PaymentCnilMention ({ children }) {
  if (!children) {
    return null
  }

  return <Mention data-testid="payment_cnil" dangerouslySetInnerHTML={{ __html: children }} />
}

PaymentCnilMention.propTypes = {
  children: PropTypes.string,
}
