import React, { useEffect, useState } from "react"
import StripeSCA from "./stripeSCA.component"
import { Bold } from "../structure/theme/styles.component"
import TunnelCustomer from "../tunnel/customer/tunnelCustomer.component"
import { usePaymentContext } from "./payment.provider"
import { useThirdPartiesContext } from "../thirdParties/thirdParties.provider"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Levy from "./levy.component"
import BancontactLazy from "./bancontactLazy.component"
import Modal from "../../components/modal/modal.component"
import { submitPayment } from "./payment.api"
import { useCampaignProduct } from "../campaign/campaign.utils"
import { PaymentCnilMention } from "./paymentCnilMention.component"
import {
  addEventTracking,
  formatPaymentForTracking,
  usePaymentProductTracking,
  useViewPageTracking,
} from "../thirdParties/gtm/tagManager"
import { sentryCaptureException } from "../thirdParties/sentry"
import { usePrice } from "../product/product.utils"
import { formatPrice } from "../../utils/format"
import { Trans, useTranslation } from "../translation/translate.component"
import useNavigation from "../navigation/useNavigation"
import { usePaymentErrorData } from "./payment.utils"
import PaymentCollapse from "./paymentBlock/paymentCollapse.component"
import BancontactImage from "./paymentImages/bancontactImage.component"
import CardImage from "./paymentImages/cardImage.component"
import Loader from "../../components/loader/loader.component"

const BANCONTACT_MODE = {
  id: 3,
  headProps: {
    text: t => {
      return t(`common:payment.page_step4_head4`)
    },
    paymentImage: BancontactImage,
  },
  component: BancontactLazy,
  name: `bancontact`,
}

const PAYMENT_MODES = [
  {
    id: 1,
    headProps: {
      text: (t, price) => {
        return `${t(`common:payment.page_step4_head1`)} ${formatPrice(price)} ${t(`common:payment.page_step4_head2`)}`
      },
      paymentImage: CardImage,
    },
    component: StripeSCA,
    name: `stripe`,
  },
  {
    id: 2,
    headProps: {
      text: t => {
        return t(`common:payment.page_step4_head3`)
      },
      paymentImage: null,
    },
    component: Levy,
    name: `levy`,
  },
]

export default function Step4Page ({ data }) {
  const {
    websiteConfigurationLocale: { cnilMentionPaymentForm },
  } = data
  const { navigate } = useNavigation()
  const [processing, setProcessing] = useState(null)
  const { product, step1, step2, isReady, sessionId } = usePaymentContext()
  const { load, isLoaded } = useThirdPartiesContext()
  const [campaignProduct, campaignProductReady] = useCampaignProduct(product)
  const { price } = usePrice(campaignProduct)
  const { t } = useTranslation()
  const paymentErrorData = usePaymentErrorData(product)
  const paymentModeUpdated = APP_CONFIG.featureFlags.bancontact ? [BANCONTACT_MODE, ...PAYMENT_MODES] : PAYMENT_MODES
  const loadingMessage =
    processing === `bancontact`
      ? t(`common:payment.page_step4_loading_bancontact`)
      : t(`common:payment.page_step4_loading_text`)

  useViewPageTracking(`TunnelCommande`, ``, `Tunnel - Paiement`)
  usePaymentProductTracking(product, 4, `Paiement`)
  useEffect(() => {
    load(`stripe`)
  }, [])

  function onSubmit (payment) {
    const toSend = {
      sessionId,
      payment,
    }
    setProcessing(`payment`)
    submitPayment(toSend)
      .then(result => {
        addEventTracking(`checkoutOption`, {
          paymentType: formatPaymentForTracking(payment),
        })
        setProcessing(null)
        navigate(`paymentSuccess`, {
          state: {
            ...result,
            email: step2.email,
          },
        })
      })
      .catch(error => {
        const errorCode = error.response?.body?.code ?? ``

        setProcessing(null)
        switch (errorCode) {
          case `order_create_ensura_error`:
            navigate(`paymentSuccessNoPolicy`, {
              state: {
                ...error,
                email: step2.email,
              },
            })
            break
          case `payment_iban_invalid`:
            navigate(`paymentFail`)
            break
          default: {
            sentryCaptureException(error, paymentErrorData)
            navigate(`paymentFail`)
          }
        }
      })
  }

  return (
    <>
      <TunnelCustomer currentStep={3} title={t(`common:payment.page_step4_payment_title`)} product={product}>
        <div>
          <Trans
            t={t}
            i18nKey="common:payment.page_step4_payment_text"
            components={{
              bold: <Bold />,
            }}
          />
        </div>
        <PaymentCollapse
          defaultItem={APP_CONFIG.featureFlags.bancontact ? 3 : 2}
          allowClose
          isReady={isReady && isLoaded(`stripe`) && campaignProduct && campaignProductReady}
          onSubmit={onSubmit}
          product={campaignProduct}
          paymentModes={paymentModeUpdated}
          price={price}
          step1={step1}
          step2={step2}
          setProcessing={setProcessing}
        />
        <PaymentCnilMention>{cnilMentionPaymentForm}</PaymentCnilMention>
      </TunnelCustomer>
      <Modal
        visible={processing !== null}
        closeButton={false}
        modalComponent={() => <Loader message={loadingMessage} />}
      />
    </>
  )
}

Step4Page.propTypes = {
  data: PropTypes.shape({
    websiteConfigurationLocale: PropTypes.shape({
      cnilMentionPaymentForm: PropTypes.any,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query {
    websiteConfigurationLocale {
      cnilMentionPaymentForm
    }
  }
`
