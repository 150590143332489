import React from "react"
import PropTypes from "prop-types"
import { Image } from "../../../components/images.component"
import { useStaticQuery, graphql } from "gatsby"

export default function CardImage ({ alt = ``, imgStyle = { width: `90%`, height: `90%` } }) {
  const { moyensDePayment } = useStaticQuery(query)

  return <Image file={moyensDePayment} alt={alt} imgStyle={imgStyle} />
}

CardImage.propTypes = {
  alt: PropTypes.string,
  imgStyle: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
}

const query = graphql`
  query {
    moyensDePayment: fileImageWithOverride(name: "moyensDePayment") {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
