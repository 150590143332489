import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Label from "../../../components/form/label.component"
import { CardNumberElement } from "react-stripe-elements"
import InfoAndError from "../../../components/form/infoAndError.component"
import { useStripeInput } from "./useStripeInputChange.hook"
import useStripeStyle from "./useStripeStyle.hook"

const CardNumberWrapper = styled.div`
  width: 100%;
  padding: 16px;
`

export default function CardNumber ({ Wrapper, label, field }) {
  const stripeStyle = useStripeStyle()
  const [onChange, onBlur] = useStripeInput(field.name)

  return (
    <CardNumberWrapper>
      <Label>{label}</Label>
      <Wrapper>
        <CardNumberElement id="cardnumber" onChange={onChange} onBlur={onBlur} style={stripeStyle} />
      </Wrapper>
      <InfoAndError field={field} />
    </CardNumberWrapper>
  )
}

CardNumber.propTypes = {
  Wrapper: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
}
