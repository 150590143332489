import React, { useMemo } from "react"
import styled from "styled-components"
import { compose, iban, required, placeholder, mask } from "../../utils/validate"
import { Button } from "../structure/theme/button.component"
import InputText from "../../components/form/inputText.component"
import RadioGroupPayment from "./radioGroupPayment.component"
import PropTypes from "prop-types"
import { formatPrice } from "../../utils/format"
import DevOnly from "../../components/devOnly.component"
import { formTrackingErrors } from "../thirdParties/gtm/tagManager"
import { usePrice } from "../product/product.utils"
import Form from "../../components/form/form.component"
import Field from "../../components/form/field.component"
import { useTranslation } from "../translation/translate.component"
import { useValueChanges } from "../../components/form/plugins/valueChanges.component"

const ButtonWrapper = styled.div`
  margin: 0 16px 16px;
`

const FormWrapper = styled.div`
  margin: 16px;
`

const LevyForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

const formInitialState = {
  accountOwner: ``,
  iban: ``,
  type: ``,
}

function validate (values) {
  return {
    accountOwner: required(values.accountOwner),
    iban: compose(required, iban)(values.iban),
    type: required(values.type),
  }
}

export default function Levy ({ product, onSubmit }) {
  const { t } = useTranslation()
  const { price, annualPurchaseOnly } = usePrice(product)

  const availablePaymentChoices = useMemo(() => {
    const withSpace = str =>
      str
        .replace(`/`, ` / `)
        .replace(`€`, ` € `)
        .replace(/  +/g, ` `)
        .trim()

    const choices = [
      {
        label: `1x`,
        value: `DDA`,
        price: withSpace(t(`common:product.price_by_year`, { price: formatPrice(price, 1) })),
      },
    ]

    if (!annualPurchaseOnly) {
      choices.unshift(
        {
          label: `12x`,
          value: `DDM`,
          price: withSpace(t(`common:product.price_by_month`, { price: formatPrice(price, 12) })),
        },
        {
          label: `4x`,
          value: `DDQ`,
          price: withSpace(t(`common:product.price_by_trim`, { price: formatPrice(price, 4) })),
        },
      )
    }

    return choices
  }, [price, annualPurchaseOnly])

  const valueChanges = useValueChanges(({ field, value, formik }) => {
    formTrackingErrors(`Tunnel - Paiement`, field, value, undefined, formik.errors[field])
  }, [])

  function onSubmitLevy (values) {
    return onSubmit({
      type: `levy`,
      paymentInfos: values,
    })
  }

  return (
    <FormWrapper>
      <LevyForm
        name="iban"
        initialValues={formInitialState}
        valueChanges={valueChanges}
        onSubmit={onSubmitLevy}
        validate={validate}
      >
        <Field component={RadioGroupPayment} squareRadio name="type" items={availablePaymentChoices} />
        <Field
          component={InputText}
          name="accountOwner"
          label={t(`common:payment.page_step4_iban_owner_label`) + `* :`}
          placeholder={t(`common:payment.page_step4_iban_owner_placeholder`)}
        />
        <DevOnly>
          <div>valid iban: FR7614410000011234567890163</div>
          <div>valid iban: BE76144100000112</div>
          <div>valid iban with failing payment: FR7630001007941234567890185</div>
          <div>valid iban with failing payment: BE76300010079412</div>
          <div>valid iban with no activation: FR7614410000011234567890000</div>
        </DevOnly>
        <Field
          component={InputText}
          name="iban"
          label={t(`common:payment.page_step4_iban_label`) + `* :`}
          placeholder={placeholder(`iban`)}
          mask={mask(`iban`)}
        />

        <ButtonWrapper>
          <Button type="submit" data-testid="pay_levy">
            {t(`common:payment.page_step4_levy_pay`)}
          </Button>
        </ButtonWrapper>
      </LevyForm>
    </FormWrapper>
  )
}

Levy.propTypes = {
  product: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
