import React, { memo, useState } from "react"
import { Elements, StripeProvider, injectStripe } from "react-stripe-elements"
import { Error } from "../../components/form/infoAndError.component"
import PropTypes from "prop-types"
import { colors, fontSizes } from "../structure/theme"
import { Image } from "../../components/images.component"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import DevOnly from "../../components/devOnly.component"
import { getTokenFromUnderwriterId } from "../product/product.utils"
import { postPaymentIntent } from "./payment.api"
import { usePaymentContext } from "./payment.provider"
import Form from "../../components/form/form.component"
import Field from "../../components/form/field.component"
import { sentryCaptureException } from "../thirdParties/sentry"
import { useTranslation } from "../translation/translate.component"
import useNavigation from "../navigation/useNavigation"
import { usePageContext } from "../navigation/pageContext.provider"
import { localeToLang } from "../translation/translate.utils"
import { required } from "../../utils/validate"
import CardNumber from "./stripe/cardNumber.component"
import CardDate from "./stripe/cardDate.component"
import CardCvc from "./stripe/cardCvc.component"
import SubmitButton from "../../components/form/submitButton.component"
import { createStripePaymentMethodData } from "./stripe/stripe.utils"

const InputContainer = styled.div`
  background-color: ${colors(`grey.g300`)};
  color: ${colors(`grey.g800`)};
  border-color: ${colors(`grey.g300`)};
  height: 50px;
  padding: 0 10px;

  & > div {
    height: 100%;
  }
`

const Notice = styled.span`
  color: ${colors(`grey.g600`)};
  font-size: ${fontSizes(`small`)};
`

const Secure = styled.div`
  width: 100%;
  margin: 0 16px;
`

const SubmitWrapper = styled.div`
  width: 100%;
  margin: 16px;
`

const StripeSCAForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
`

const formInitialState = {
  cardNumber: ``,
  cardCvc: ``,
  cardExpiry: ``,
}

function validate (values) {
  return {
    cardNumber: values.cardNumberError || required(values.cardNumber),
    cardCvc: values.cardCvcError || required(values.cardCvc),
    cardExpiry: values.cardExpiryError || required(values.cardExpiry),
  }
}

function StripeForm ({ stripe, onSubmit, setProcessing, step1, step2 }) {
  const { threedsecure } = useStaticQuery(query)
  const [loading, setLoading] = useState(false)
  const { sessionId } = usePaymentContext()
  const [errorStripe, setErrorStripe] = useState(``)
  const { t } = useTranslation()
  const { navigate } = useNavigation()

  function onSubmitCardData (values, actions) {
    setLoading(true)
    setProcessing(`payment`)
    const data = {
      payment_method_data: createStripePaymentMethodData(step1, step2),
      receipt_email: step2.email,
    }

    return postPaymentIntent(sessionId)
      .then(({ token }) => stripe.handleCardPayment(token, data))
      .then(result => {
        if (result.paymentIntent && result.paymentIntent.status === `succeeded`) {
          return result
        }

        throw result?.error || new Error()
      })
  }

  function onSubmitSuccess (values, actions, submitResult) {
    return onSubmit({
      type: `stripe`,
      paymentInfos: { paymentIntentId: submitResult.paymentIntent.id },
    })
  }

  function onSubmitFail (values, actions, error) {
    // Payment intent failed
    if (error.response?.body?.code) {
      sentryCaptureException(error)
      navigate(`paymentFail`)
    } else if (error.code) {
      if (error.code === `card_declined`) {
        navigate(`paymentFail`)
      } else {
        setErrorStripe(`${t(`common:payment.stripe_message_error`)}${error.message}`)
      }
    } else {
      setErrorStripe(t(`common:payment.stripe_message_error3`))
      sentryCaptureException(error)
    }

    setProcessing(null)
    setLoading(false)
  }

  return (
    <StripeSCAForm
      name="stripe_card"
      initialValues={formInitialState}
      onSubmit={onSubmitCardData}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
      validate={validate}
    >
      <DevOnly>
        <h2>3dsecure2 (SCA)</h2>
        <div>3d secure: 4000000000003063</div>
        <div>Valid card: 4242424242424242</div>
        <div>Valid card with failing payment: 4000000000000002</div>
        <div>Payment order failure: 4444444444444444</div>
      </DevOnly>

      <Field
        component={CardNumber}
        Wrapper={InputContainer}
        label={t(`common:payment.stripe_card_number_label`) + `*`}
        name="cardNumber"
      />
      <Field
        component={CardDate}
        Wrapper={InputContainer}
        label={t(`common:payment.stripe_card_date_label`) + `*`}
        name="cardExpiry"
      />
      <Field
        component={CardCvc}
        Wrapper={InputContainer}
        label={t(`common:payment.stripe_card_crypto_label`) + `*`}
        name="cardCvc"
      />
      <Secure>
        <Image file={threedsecure} alt="3dSecure" />
      </Secure>
      <Secure>
        <Notice>{t(`common:payment.stripe_secure_notice`)}</Notice>
      </Secure>
      <SubmitWrapper>
        {errorStripe && <Error>{errorStripe}</Error>}
        <SubmitButton data-testid="pay_stripe" disabled={loading}>
          {!loading ? t(`common:payment.stripe_button_text`) : t(`common:payment.stripe_button_text_loading`)}
        </SubmitButton>
      </SubmitWrapper>
    </StripeSCAForm>
  )
}

StripeForm.propTypes = {
  stripe: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  step1: PropTypes.object.isRequired,
  step2: PropTypes.object.isRequired,
}

const query = graphql`
  query {
    threedsecure: file(name: { eq: "3dsecure" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StripeFormInjected = injectStripe(StripeForm)

function _Stripe ({ onSubmit, setProcessing, product, step1, step2 }) {
  // Better use lang than locale for Stripe
  // https://stripe.com/docs/js/appendix/supported_locales
  const { locale } = usePageContext()
  const apiKey = getTokenFromUnderwriterId(product)

  return (
    <StripeProvider apiKey={apiKey}>
      <Elements locale={localeToLang(locale)}>
        <StripeFormInjected step1={step1} step2={step2} onSubmit={onSubmit} setProcessing={setProcessing} />
      </Elements>
    </StripeProvider>
  )
}

_Stripe.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  step1: PropTypes.object.isRequired,
  step2: PropTypes.object.isRequired,
}

export default memo(_Stripe)
