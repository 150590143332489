import React from "react"
import { useTranslation } from "../../translation/translate.component"
import PropTypes from "prop-types"
import Collapse from "../../../components/collapse/collapseMultiple.component"
import { Collapsed } from "./collapsed.component"
import { MandatoryFields } from "./mandatoryFields.component"
import Head from "./head.component"
import styled from "styled-components"

const ComponentWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`

export default function PaymentCollapse ({
  price,
  product,
  onSubmit,
  setProcessing,
  step1 = {},
  step2 = {},
  paymentModes = [],
  isReady = false,
  defaultItem = 2,
  allowClose = false,
}) {
  const { t } = useTranslation()

  return (
    <>
      {isReady && (
        <Collapse items={paymentModes} allowClose={allowClose} defaultItem={defaultItem}>
          {({ ref, item, style, toggle, isOpen }) => {
            const Component = item.component

            return (
              <div key={item.id}>
                <Head
                  onClick={() => toggle(item)}
                  active={isOpen}
                  Image={item.headProps.paymentImage}
                  text={item.headProps.text(t, price)}
                  data-testid={`collapse_pay_${item.name}`}
                />
                <Collapsed isOpen={isOpen} style={style} ref={ref}>
                  <ComponentWrapper>
                    <Component
                      product={product}
                      onSubmit={onSubmit}
                      setProcessing={setProcessing}
                      step1={step1}
                      step2={step2}
                      isOpen={isOpen}
                    />
                  </ComponentWrapper>
                </Collapsed>
              </div>
            )
          }}
        </Collapse>
      )}
      <MandatoryFields>{`* ${t(`common:generic.form_required_mention`)}`}</MandatoryFields>
    </>
  )
}

PaymentCollapse.propTypes = {
  price: PropTypes.number,
  product: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  step1: PropTypes.object,
  step2: PropTypes.object,
  paymentModes: PropTypes.array,
  isReady: PropTypes.bool,
  defaultItem: PropTypes.number,
  allowClose: PropTypes.bool,
}
