import React, { useMemo } from "react"
import _uniqueId from "lodash/uniqueId"
import { useTranslation } from "../../translation/translate.component"
import PropTypes from "prop-types"
import styled from "styled-components"
import { colors } from "../../structure/theme"
import breakpoint from "styled-components-breakpoint"

const HeadContainer = styled.div`
  padding-left: 20px;
  margin-top: 15px;
  height: 60px;
  cursor: pointer;
  background-color: ${colors(`grey.g200`)};
  display: flex;
  align-items: center;
  flex-direction: row;
`

const Radio = styled.input.attrs({ type: `radio` })`
  margin-right: 20px;
  cursor: pointer;
`

const Label = styled.label`
  margin-right: 20px;
  flex: 1;

  &:hover {
    cursor: pointer;
  }

  ${breakpoint(`small`)`
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
  `}
`

export default function Head ({ text, Image, active = false, onClick }) {
  const [id] = useMemo(() => _uniqueId(`radio-payment-`))
  const preventClick = e => {
    // label receives one click, propagate it to parent. that's cool.
    // label also simulates one click to the input it htmlFor for. that's cool.
    // both trigger 2 clicks. that's not cool
    e.preventDefault()
  }
  const { t } = useTranslation()

  return (
    <HeadContainer onClick={onClick} data-testid={arguments[0][`data-testid`] || undefined}>
      <Radio checked={active} readOnly id={id} />
      <Label htmlFor={id} onClick={preventClick}>
        {text}
      </Label>
      {Image && <Image alt={t(`common:payment.page_step4_cards`)} />}
    </HeadContainer>
  )
}

Head.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  Image: PropTypes.func,
  active: PropTypes.bool,
}
