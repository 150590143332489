import { useThemeContext } from "../../structure/theme"
import { useMemo } from "react"

export default function useStripeStyle () {
  const { colors: themeColors } = useThemeContext()

  return useMemo(
    () => ({
      base: {
        color: themeColors.secondary,
        fontWeight: 500,
        fontSize: `16px`,
        lineHeight: `50px`,
        fontSmoothing: `antialiased`,

        "::placeholder": {
          color: themeColors.grey.g700,
        },
        ":-webkit-autofill": {
          color: themeColors.grey.g700,
        },
      },
      invalid: {
        color: themeColors.error,

        "::placeholder": {
          color: themeColors.grey.g700,
        },
      },
      complete: {
        color: themeColors.valid,
      },
    }),
    [themeColors],
  )
}
