import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Label from "../../../components/form/label.component"
import { CardCvcElement } from "react-stripe-elements"
import InfoAndError from "../../../components/form/infoAndError.component"
import { useStripeInput } from "./useStripeInputChange.hook"
import breakpoint from "styled-components-breakpoint"
import useStripeStyle from "./useStripeStyle.hook"

const CardDateAndCrypto = styled.div`
  width: 100%;
  padding: 16px;

  ${breakpoint(`medium`)`
    width: 50%
  `}
`

export default function CardCvc ({ Wrapper, label, field }) {
  const stripeStyle = useStripeStyle()
  const [onChange, onBlur] = useStripeInput(field.name)

  return (
    <CardDateAndCrypto>
      <Label>{label}</Label>
      <Wrapper>
        <CardCvcElement id="cvc" onChange={onChange} onBlur={onBlur} style={stripeStyle} />
      </Wrapper>
      <InfoAndError field={field} />
    </CardDateAndCrypto>
  )
}

CardCvc.propTypes = {
  Wrapper: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
}
