import { useCallback } from "react"
import { useFormikContext } from "formik"

export function useStripeInput (name) {
  const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext()

  const onChange = useCallback(
    event => {
      const value = event.error ? `error` : `ok`
      const errorMessage = event.error?.message ?? null

      setFieldValue(name, value)
      setFieldTouched(name, true)
      setFieldError(name, errorMessage)
      setFieldValue(`${name}Error`, errorMessage)
    },
    [name],
  )

  return [onChange, onChange]
}
