import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import InfoAndError from "../../components/form/infoAndError.component"
import { colors, fontSizes } from "../structure/theme"
import { StyledField } from "../../components/form/styledField.component"
import _noop from "lodash/noop"
import { useTranslation } from "../translation/translate.component"
import { useFormikContext } from "formik"

const LabelRadio = styled.label`
  cursor: pointer;
  padding: 25px 30px 20px 30px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  border: 2px solid ${props => colors(props.checked ? `valid` : `grey.g600`)};
  color: ${props => colors(props.checked ? `grey.g800` : `grey.g600`)};
  font-size: ${fontSizes(`large`)};
  align-items: baseline;

  &:focus {
    outline: none;
  }
`

const InputRadio = styled.input`
  cursor: pointer;

  transform: translateY(-5px);
`

const Times = styled.span`
  font-size: ${fontSizes(`xxx_large`)};
  font-weight: ${props => (props.checked ? 900 : 400)};
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin-left: 20px;
  margin-right: 10px;
`

const Price = styled.span`
  font-weight: ${props => (props.checked ? 900 : 400)};
  margin-left: auto;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export default function RadioGroupPayment ({ field, items, renderInfo }) {
  const { t } = useTranslation()
  const { name, value: selectedValue } = field
  const { setFieldValue, setFieldTouched } = useFormikContext()

  return (
    <StyledField>
      <Wrapper role="radiogroup" data-testid={`form_field_${name}`}>
        {items.map(({ label, value, price }) => {
          const selected = value === selectedValue

          return (
            <LabelRadio
              key={value}
              htmlFor={name}
              onClick={() => {
                setFieldValue(name, value)
                setFieldTouched(name, true)
              }}
              checked={selected}
              tabIndex="-1"
              role="radio"
              aria-checked={selected}
              data-testid={`form_field_${name}_${label}`}
            >
              <InputRadio
                type="radio"
                id={`${name}-${value}`}
                name={name}
                onClick={() => {
                  setFieldValue(name, value)
                  setFieldTouched(name, true)
                }}
                onChange={_noop}
                checked={selected}
              />
              <Times checked={selected}>{label}</Times>
              <span>{t(`common:payment.page_step4_no_fee`)}</span>
              <Price checked={selected} data-testid={`levy-price-${value}`}>
                {price}
              </Price>
            </LabelRadio>
          )
        })}
        <InfoAndError field={field} renderInfo={renderInfo} />
      </Wrapper>
    </StyledField>
  )
}

RadioGroupPayment.propTypes = {
  renderInfo: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
