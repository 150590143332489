export function createStripePaymentMethodData (step1, step2) {
  const { address, streetNumber, postalCode, city } = step1
  const {
    phone,
    email,
    firstname,
    lastname,
    differentBilling,
    billingAddress,
    billingStreetNumber,
    billingPostalCode,
    billingCity,
  } = step2

  return {
    billing_details: {
      name: `${firstname} ${lastname}`,
      phone,
      email,
      address: differentBilling
        ? {
            line1: billingAddress,
            line2: billingStreetNumber,
            postal_code: billingPostalCode,
            city: billingCity,
            country: APP_CONFIG.country.toUpperCase(),
          }
        : {
            line1: address,
            line2: streetNumber,
            postal_code: postalCode,
            city: city,
            country: APP_CONFIG.country.toUpperCase(),
          },
    },
  }
}
